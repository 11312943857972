import { FC } from "react";
import { Col } from "../Account/History/Order/Detail/Styled";
import { PictureList, PictureListItemType } from "../List/PictureList";
import { cssListVertical, StyledList, StyledListItem } from "../List/StyledList";
import { StyledBrandBasis, StyledBrandBasisContent, StyledPlus, StyledPluses } from "./Styled";
import { Panel } from "@/styles/utils/StyledPanel";
import { SectionTitle } from "@/styles/utils/Utils";
import { colors } from "@/styles/utils/vars";
import { Icon } from "@/ui/Icon";
import { Typography } from "@/ui/Typography/Typography";
import { LIST_PICTURE_ITEMS } from "@/utils/constants";
import { Container } from "styles/utils/StyledGrid";
const COLORS_PLUSES = [colors.white, colors.brand.yellow, colors.brand.orange, colors.brand.green];
const LIST_PICTURE_ITEMS_SEC: PictureListItemType[] = [{
  title: "Товары для вашего бизнеса",
  description: "Компания «Гростер» предлагает различные товары для бизнеса оптом по низким ценам. Мы полностью ориентированы на взаимовыгодное долгосрочное сотрудничество с покупателями, поэтому реализуем только качественную и актуальную для предпринимателей продукцию."
}, {
  title: "Ассортиментное разнообразие",
  description: "В каталоге представлен большой выбор наименований для различных направлений бизнеса — от розничной торговли и предприятий общепита до компаний, занимающихся клининговыми услугами, производством и продажей продуктов питания, напитков, а также спортзалов, цветочных магазинов, медицинских учреждений. Не упустите возможность недорого купить товары для бизнеса в интернет-магазине «Гростер» — инвестируйте в свое дело без рисков и переплат."
}];
const STANDART_LIST = ["фиксированные цены;", "отсутствие скрытых комиссий, навязанных услуг;", "различные способы внесения оплаты;", "адресная доставка по городу или самовывоз;", "доставка по России транспортными компаниями;", "соответствие продукции стандартам качества."];
export const BrandBasis: FC = () => {
  const plusesElements = <StyledPluses>
      {COLORS_PLUSES.map((c, index) => <StyledPlus key={index}>
          <Icon NameIcon={"PlusSquare"} fill={c} size={"fill"} />
        </StyledPlus>)}
    </StyledPluses>;
  return <StyledBrandBasis>
      <Container>
        {plusesElements}
        <SectionTitle>
          В основу бренда мы заложили три понятия, на которых базируются
          коммуникации
        </SectionTitle>
        <StyledBrandBasisContent>
          <Col>
            <PictureList items={LIST_PICTURE_ITEMS} className={cssListVertical} />
          </Col>
          <Col>
            <PictureList items={LIST_PICTURE_ITEMS_SEC} className={cssListVertical} />
          </Col>
          <Col>
            <Panel>
              <Typography variant={"h3"}>
                Коммерческое предложение от «Гростер»
              </Typography>
              <Typography>
                Всем категориям клиентов предоставляются следующие условия
                взаимодействия:
              </Typography>
              <StyledList className={cssListVertical} isDefault>
                {STANDART_LIST.map((l, i) => <StyledListItem key={i}>{l}</StyledListItem>)}
              </StyledList>
              <Typography>
                Звоните по контактным телефонам или оставляйте онлайн-заявку
                на сайте, чтобы заказать товар для начала бизнеса или его
                расширения на выгодных условиях.
              </Typography>
            </Panel>
          </Col>
        </StyledBrandBasisContent>
      </Container>
    </StyledBrandBasis>;
};